/* @import 'reset.css'; */
/* @import 'normalize.css'; */
@import '~bootstrap-reboot/dist/reboot.css';
@import "assets/font/Hurricane.css";
@import "assets/font/PTSans.css";

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #4f4f4f #828282;
}

html, body {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Hurricane', cursive;
  font-family: 'PT Sans', sans-serif;
  
  letter-spacing: 1px;
  line-height: 1.4em;
  background-color: #3C3C3B;
  color: #FCFCFB;
  scrollbar-face-color: #828282;
  scrollbar-3dlight-color: #9b9b9b;
  scrollbar-highlight-color: #a8a8a8;
  scrollbar-track-color: #4f4f4f;
  scrollbar-arrow-color: #fff;
  scrollbar-shadow-color: #696969;
  scrollbar-dark-shadow-color: #5c5c5c;
}

::-webkit-scrollbar{
  width:6px;
  height:10px;
}
::-webkit-scrollbar-track{
  background:#828282;
}
::-webkit-scrollbar-thumb{
  background:#353535;
  border-radius:0px;
  height: 15px;
}
::-webkit-scrollbar-thumb:hover{
  background:#424242;
  cursor:pointer;
}
::-webkit-scrollbar-thumb:active{
  background:#292929
}

ol, ul {
  padding-inline-start: 35px;
}

@media screen and (max-width: 767px) {
  ol, ul {
    padding-inline-start: 25px;
  }
}

.h1, h1 {
  font-size: 2.25rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

#root {
  max-width: 100%;
}

.main {
  padding: 0 15px;
  transition: all 1.2s ease-in-out;
}

.content {
  height: 0px;

  overflow: hidden;
  transition: all 1s ease-in-out;
  text-align: center;
  max-width: 450px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: content-box;
}

@media screen and (min-width: 450px) {
  .content {
    padding-left: 45px;
    padding-right: 45px;
  }
}



.Footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 8px 10px;
  opacity: 0.75;
}

.MuiModal-root {
  color: #3C3C3B;
}

.MuiFormControlLabel-label {
  font-size: 13px!important;
  color: rgba(255, 255, 255, 0.6);
}

.MuiFormControlLabel-root {
  margin-right: 0px!important;
}

.MuiCheckbox-root + .MuiFormControlLabel-label {
  text-align: left;
}

@media screen and (max-width: 359px) {
  .MuiCheckbox-root + .MuiFormControlLabel-label {
    font-size: 11px!important;
  }
}