.Logo {
    width: 32em;
    max-width: 100%;
    /* height: 2.55rem; */
    margin: 0 auto;
    line-height: 0;
}

.Logo svg .letters * {
    transition: all 1.2s ease-in-out;
}

.Logo svg .letter-d,
.Logo svg .letter-e,
.Logo svg .letter-v {
    transform: translateY(-100px);
}

.Logo svg .letter-p,
.Logo svg .letter-l,
.Logo svg .letter-a,
.Logo svg .letter-y {
    transform: translateY(200px);
}

.Logo svg .letter-g,
.Logo svg .letter-r,
.Logo svg .letter-o,
.Logo svg .letter-u,
.Logo svg .letter-n,
.Logo svg .letter-d2 {
    transform: translateX(500px);
}