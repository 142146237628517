.swiper.swiper-cube {
    padding-bottom: 30px;
    margin-top: 30px;
}

.swiper.swiper-cube .swiper-wrapper {
    padding-bottom: 20px;
}

.swiper.swiper-cube .swiper-slide {
    -webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    visibility:visible;
    backface-visibility: hidden;
    /* padding-bottom: 15px; */
}

.swiper.swiper-cube .swiper-cube-shadow {
    height: 400px!important;
}