.Slogan {
    /* font-weight: 700; */
    font-size: 1em;
    /* margin-bottom: 45px; */
    padding-left: 10px;
    padding-right: 10px;
    color: rgba(255, 255, 255, 0.6);
}

.Slogan p {
    margin-bottom: 1.3em;
}

.Slogan p:last-child {
    margin-bottom: 0;
}

.Slogan button {
    margin-top: 15px;
}

@media screen and (max-height: 599px) {
    .Slogan {
        padding: 0;
    }

    .Slogan p {
        margin-bottom: 0.8em;
    }

    .Slogan .contacts-us-text {
        display: none;
    }
}