.Privacy h3 {
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}

.Privacy ol li {
    font-weight: 600;
    margin-top: 2.2rem;
}

.Privacy .subtitle {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.Privacy .last-update {
    margin-top: 45px;
    font-size: 0.85rem;
    font-style: italic;
}

.Privacy p {
    overflow-wrap: break-word;
}