.Loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(60, 60, 59, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.Loading.disabled {
    display: none;
}

